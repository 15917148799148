import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Intro from '../components/intro'

import SEOPage from '../components/SEO/Page'

class Covid19Page extends React.Component {
  render() {
    const { location, data } = this.props

    const covidContent19 = data.prismicCovid19.data

    var heroImgs = []
    var heroImgsMobile = []

    covidContent19.hero.forEach(item => {
      heroImgs.push(item.desktop_image.url)
      heroImgsMobile.push(item.mobile_image.url)
    })

    return (
      <Layout location={location}>
        <SEOPage
          title={covidContent19.title && covidContent19.title}
          location={location}
        />
        <Hero heroImgs={heroImgs} heroImgsMobile={heroImgsMobile} />
        <Intro
          id="requirements"
          title={covidContent19.lead_title}
          lead={covidContent19.lead_text.html}
          imageUrl={covidContent19.lead_image.url}
          imageAlt={covidContent19.lead_image.alt}
        />
      </Layout>
    )
  }
}

export const pageCovidQuery = graphql`
  query {
    prismicCovid19 {
      data {
        title
        hero {
          desktop_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
          mobile_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
        }
        lead_title
        lead_text {
          html
        }
        lead_image {
          url
          alt
          localFile {
            childImageSharp {
              id
            }
          }
        }
      }
    }
  }
`

export default Covid19Page
